import React, { Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import { federatedComponent } from '@unisporkal/federation';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import CreditsRemaining from './CreditsRemaining';
import TeamCreditsRemaining from './TeamCreditsRemaining';
import SubscriptionsRemaining from './SubscriptionsRemaining';
import SubscriptionAlert from './SubscriptionAlert';
import OnboardingVideoPanel from './OnboardingVideoPanel';
import styles from './YourPlansSection.module.scss';
import usePlanInfoQuery from '../../../../hooks/queries/usePlanInfoQuery';
import AIGeneration from './AIGeneration';

const UserPanelCNS = federatedComponent(
  'customer_notifications',
  './UserPanel'
);

const YourPlansSection = ({ closePanel }) => {
  // Refresh Plan Info section every time panel is opened
  const { data: planInfoQuery } = usePlanInfoQuery({ staleTime: 200 });
  const {
    creditsAvailable,
    subscriptionDownloads,
    showSubsRemaining,
    showCreditsRemaining,
    showSubscriptionAlert,
    subscriptionWarningMessageType,
    resumeDate,
    subscriptionDaysRange,
    customMedia,
  } = planInfoQuery.agreementsOverview;

  const { teamCreditsRemaining, showTeamCreditsRemaining } =
    planInfoQuery.teamCredits;

  const { showOnboardingVideo, isOnboardingVideoPPV } =
    planInfoQuery.onboardingVideo;

  const t = useTranslations();
  const links = useIstockLinks();
  const messageDataRef = useRef({
    pTagIloxKey: '',
    aTagIloxKey: '',
    aTagDataNav: '',
    aTagDataUILocation: '',
    aTagHref: '',
    hasATag: false,
    hasAFigure: false,
    hasNoWrapCta: false,
    isDisplay: false,
  });

  const showYourPlans =
    showSubscriptionAlert ||
    showSubsRemaining ||
    showCreditsRemaining ||
    showTeamCreditsRemaining ||
    !!customMedia?.showAgreementInfo;

  messageDataRef.current = links.userAccountSubscriptionWarning({
    subscriptionWarningMessageType,
    resumeDate,
    subscriptionDaysRange,
    noDownloadsRemaining: subscriptionDownloads === 0,
  });

  return (
    <div data-testid="yourPlans">
      {showYourPlans && <h4 data-testid="yourPlansTitle">{t('your_plans')}</h4>}
      <Suspense>
        <UserPanelCNS>
          {/* send subscription alert to CN. CN will decide whether or not to show it  */}
          {showSubscriptionAlert &&
            messageDataRef.current &&
            messageDataRef.current.isDisplay && (
              <SubscriptionAlert messageData={messageDataRef.current} />
            )}
        </UserPanelCNS>
      </Suspense>

      <table className={styles.creditsSubsTable}>
        {showSubsRemaining && (
          <SubscriptionsRemaining
            subscriptionDownloads={subscriptionDownloads}
          />
        )}
        {showCreditsRemaining && (
          <CreditsRemaining creditsAvailable={creditsAvailable} />
        )}
        {showTeamCreditsRemaining && (
          <TeamCreditsRemaining teamCreditsRemaining={teamCreditsRemaining} />
        )}
        {customMedia.showAgreementInfo && (
          <AIGeneration
            hasUnlimitedAgreement={customMedia.hasUnlimitedAgreement}
            generationsRemaining={customMedia.generationsRemaining}
          />
        )}
      </table>

      {showOnboardingVideo && (
        <OnboardingVideoPanel
          isPPV={isOnboardingVideoPPV}
          closePanel={closePanel}
        />
      )}

      {showYourPlans && (
        <hr
          className={styles.horizontalDivider}
          data-testid="yourPlansDivider"
        />
      )}
    </div>
  );
};

YourPlansSection.propTypes = {
  closePanel: PropTypes.func.isRequired,
};

export default YourPlansSection;
