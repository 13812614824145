import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import propTypes from 'prop-types';
import styles from './YourPlansSection.module.scss';

const SubscriptionsRemaining = ({ subscriptionDownloads }) => {
  const t = useTranslations();
  return (
    <tr
      className={styles.remaining}
      data-testid="subsRemaining"
    >
      <td className={styles.count}>{subscriptionDownloads}</td>
      <td>
        <p>{t('subscription_downloads_remaining_this_month')}</p>
      </td>
    </tr>
  );
};

SubscriptionsRemaining.propTypes = {
  subscriptionDownloads: propTypes.number.isRequired,
};

export default SubscriptionsRemaining;
