import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import PropTypes from 'prop-types';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
/* eslint-disable import/no-unresolved */
import HeaderLogo from '../HeaderLogo/HeaderLogo.tsx';
import VisualGpsLogo from '../../../icons/visualGPSiStockLogo.svg?icon';
import VisualGpsMobileLogo from '../../../icons/visualGPSMobileLogo.svg?icon';
import styles from './MinimalHeader.module.scss';

const MinimalHeader = ({ isVisualGps }) => {
  const siteMap = useSiteMap();
  const href = siteMap.visualGpsLanding();
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: 'visual_gps_insights',
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: 'visual-gps-header',
    });
    ga4Track(ga4Event);
  };

  const minimalHeaderContent = () => <HeaderLogo />;
  const visualGpsContent = () => (
    <a
      data-testid="nav-link-visual_gps_insights"
      href={href}
      onClick={navigationTracking}
    >
      <VisualGpsLogo
        role="img"
        className={styles.desktop}
        alt="iStock Visual GPS Logo"
        data-testid="visualGpsLogo"
      />
      <VisualGpsMobileLogo
        role="img"
        className={styles.mobile}
        alt="iStock Visual GPS Mobile Logo"
        data-testid="visualGpsMobileLogo"
      />
    </a>
  );

  return (
    <header
      data-testid="minimal-header"
      className={styles.container}
    >
      {isVisualGps ? visualGpsContent() : minimalHeaderContent()}
    </header>
  );
};

MinimalHeader.defaultProps = { isVisualGps: false };
MinimalHeader.propTypes = { isVisualGps: PropTypes.bool };

export default MinimalHeader;
