import React from 'react';
import PropTypes from 'prop-types';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import styles from './NavLink.module.scss';

const NavLink = ({ dataNavLabel, link, onClick }) => {
  const pricingStyles =
    dataNavLabel === 'PlansAndPricing' ? styles.pricingLink : '';
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: link.gaName,
      link_url: link.href,
      ui_element_location: link.gaLocation || 'header',
      navigation_selection: link.gaName,
    });
    ga4Track(ga4Event);
  };
  const handleClick = () => {
    navigationTracking();
    onClick(link);
  };

  return (
    <a
      className={styles.link}
      id={pricingStyles}
      href={link.href}
      target={link.target}
      data-nav={`nav_${dataNavLabel}`}
      data-testid="nav-link"
      onClick={handleClick}
      rel={link.target === '_blank' ? 'noopener noreferrer' : ''}
    >
      {link.name}
    </a>
  );
};

export default NavLink;

NavLink.defaultProps = {
  /* eslint-disable @typescript-eslint/no-empty-function */
  onClick: () => {},
};

NavLink.propTypes = {
  dataNavLabel: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    gaLocation: PropTypes.string,
    gaName: PropTypes.string.isRequired,
    target: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
};
