import React from 'react';

import PropTypes from 'prop-types';
import NotificationsList from '../shared/NotificationsList/NotificationsList';
import FullHeader from './FullHeader/FullHeader.tsx';
import MinimalHeader from './MinimalHeader/MinimalHeader';
import styles from './Header.module.scss';

const Header = ({ layout, currentPage, showSearchBarToggle }) => {
  const headerLayout = () => {
    switch (layout) {
      case 'checkout':
      // intentional fallthrough
      case 'minimal':
        return <MinimalHeader />;
      case 'visual_gps':
        return <MinimalHeader isVisualGps />;
      default:
        return (
          <FullHeader
            showSearchBarToggle={showSearchBarToggle}
            currentPage={currentPage}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <NotificationsList currentPage={currentPage} />
      {headerLayout()}
    </div>
  );
};

export default Header;

Header.propTypes = {
  currentPage: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  showSearchBarToggle: PropTypes.bool,
};

Header.defaultProps = {
  showSearchBarToggle: false,
};
