import React, { Suspense, useState, useEffect } from 'react';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { ga4Track, loginInteraction, navigationEvent } from '@unisporkal/ga4';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import usePanelsControls from '../../../../hooks/usePanelsControls';
import AccountLink from '../AccountLink/AccountLink';
import PremiumPlusVideoPromo from '../PremiumPlusVideoPromo/PremiumPlusVideoPromo';
import YourPlansSection from '../YourPlansSection/YourPlansSection';
import styles from './UserAccountNav.module.scss';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

const RightPanel = lazyComponent(() => import('../RightPanel/RightPanel'));

const UserAccountNav = () => {
  const siteMap = useSiteMap();
  const links = useIstockLinks();
  const t = useTranslations();
  const [currentLocation, setCurrentLocation] = useState('');

  const {
    data: { emailAddress: userEmail },
  } = useHeaderPropsQuery();
  const { showPanel, togglePanel, closePanel } = usePanelsControls(
    styles.panelOpen,
    true
  );

  useEffect(() => {
    const returnPath = window.location.pathname + window.location.search;
    setCurrentLocation(returnPath);
  }, []);

  const trackMenuClick = (item) => {
    const ga4Event = navigationEvent({
      link_text: item.ga4Name,
      link_url: item.href,
      ui_element_location: 'account_panel',
      navigation_selection: item.ga4Name,
    });

    ga4Track(ga4Event);
  };

  const trackSignOut = () => {
    const ga4Event = loginInteraction({
      event_name: 'logout',
      ui_element_location: 'account_panel',
    });
    ga4Track(ga4Event);
    trackMenuClick({
      ga4Name: 'Sign out',
      href: siteMap.signOutPath(encodeURIComponent(currentLocation)),
    });
  };

  const buildListItem = (item) =>
    item.isDisplay && (
      <li
        role="menuitem"
        key={`${item.class}`}
        className={`${item.class === 'boards' ? styles.boardsListItem : ''}`}
      >
        <a
          href={item.href}
          data-ui-location={item.dataUiLocation}
          data-testid={`account_panel_${item.ga4Name
            .toLowerCase()
            .replace(' ', '_')}`}
          onClick={() => {
            trackMenuClick(item);
          }}
        >
          <div className={styles.listLink}>
            <span className={`${styles[item.class]} ${styles.menuIcon}`} />
            <span>{t(item.iLoxKey)}</span>
          </div>
        </a>
      </li>
    );

  const buildContent = () => (
    <aside className={styles.headerSidePanel}>
      <PremiumPlusVideoPromo />
      <YourPlansSection
        showPanel={showPanel}
        closePanel={closePanel}
      />

      <div data-testid="yourAccount">
        <h4 className={styles.yourAccount}>{t('your_account')}</h4>
        <p
          data-testid="navAccountEmail"
          className={styles.emailNavbar}
        >
          {userEmail || ''}
        </p>
        <ul>{links.userAccountNav().map((item) => buildListItem(item))}</ul>
      </div>

      <hr className={styles.horizontalDivider} />

      <div data-testid="yourProjects">
        <h4>{t('your_projects')}</h4>
        <ul>
          {links.userAccountProjects().map((item) => buildListItem(item))}
        </ul>
      </div>

      <hr className={styles.horizontalDivider} />

      <div data-testid="signOutPath">
        <ul>
          <li role="menuitem">
            <a
              href={siteMap.signOutPath(encodeURIComponent(currentLocation))}
              data-nav="nav_Account_SignOut"
              data-testid="account_panel_sign_out"
              data-ui-location="header_side_panel_right"
              onClick={trackSignOut}
            >
              {t('sign_out')}
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );

  return (
    <>
      <AccountLink togglePanel={togglePanel} />
      {showPanel && (
        <Suspense>
          <RightPanel
            showPanel={showPanel}
            closePanel={closePanel}
          >
            <div>{buildContent()}</div>
          </RightPanel>
        </Suspense>
      )}
    </>
  );
};

export default UserAccountNav;
